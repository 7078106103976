@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Kalam&display=swap');

body {
  min-height: 100%;
  background-color: #3cab93;
}

.App-header {
  font-family: 'Gloria Hallelujah', cursive;
  background-color: #3b3bbc;
  min-height: 16vh;
  font-size: 4rem;
  letter-spacing: 2px;
  color: white;
  border-bottom: 2px solid whitesmoke;
}

.fancy {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 2.50rem;
}

.aar-img {
  background-color: #3b3bbc;
}

.aar-img:hover {
  transition: all 0.5s ease;
  color: #3cab93;
  box-shadow: 0 0 .15rem black;
}

.aar-gif {
  background: url(./assets/img/aarGif.gif) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 42%;
  border-radius: 1rem;
  height: 100%;
}